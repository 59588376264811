import { UserType } from "@/hooks/useUrlParams";
import { IAttendance } from "@/types/attendance-sheet.types";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { getDayStatus } from "@/utils/attendance";
import { startCase } from "lodash-es";

export const getAttendanceTableColumns = ({
  dataQuery,
  endQueryParam,
  isFamilyView,
  userType,
}: {
  dataQuery: string | null;
  endQueryParam: string;
  isFamilyView: boolean;
  userType: UserType;
}) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (_: unknown, attendanceSheet: IAttendance) => {
      const _url = `/attendance/${attendanceSheet?.id}?data=${dataQuery}&platform=${attendanceSheet.platform}&end=${endQueryParam}`;

      const _status = attendanceSheet?.status?.toLowerCase();
      const _isEditable = !(_status === "incomplete" && isFamilyView);
      if (!_isEditable) {
        return attendanceSheet?.child?.name;
      }

      return <Link to={_url}>{attendanceSheet?.child?.name}</Link>;
    },
    sorter: (a: IAttendance, b: IAttendance) =>
      (a?.child?.name || "").localeCompare(b?.child?.name || ""),
  },
  {
    title: "Child ID",
    key: "id",
    render: (_: unknown, datum: IAttendance) => {
      return datum?.child?.id;
    },
    sorter: (a: IAttendance, b: IAttendance) =>
      (a?.child?.id || 0) - (b?.child?.id || 0),
  },
  ...(userType === UserType.PROVIDER
    ? [
        {
          title: "Household ID",
          key: "id",
          render: (_: unknown, datum: IAttendance) => {
            return datum?.child?.householdId;
          },
          sorter: (a: IAttendance, b: IAttendance) =>
            (a?.child?.householdId || 0) - (b?.child?.householdId || 0),
        },
      ]
    : [
        {
          title: "Provider ID",
          key: "id",
          render: (_: unknown, datum: IAttendance) => {
            return datum?.provider?.id;
          },
          sorter: (a: IAttendance, b: IAttendance) =>
            (a?.provider?.id || 0) - (b?.provider?.id || 0),
        },
      ]),
  {
    title: "Period",
    key: "period",
    render: (_: unknown, datum: IAttendance) => {
      const _parsedStart = dayjs(datum?.periodStartDate);
      const _parsedEnd = dayjs(datum?.periodEndDate);

      if (
        _parsedStart.month() !== _parsedEnd.month() ||
        _parsedStart.year() !== _parsedEnd.year()
      ) {
        return `${dayjs(datum?.periodStartDate).format(
          "MMM DD, YYYY"
        )} - ${dayjs(datum?.periodEndDate).format("MMM DD, YYYY")}`;
      }

      return `${dayjs(datum?.periodStartDate).format("MMM DD")}-${dayjs(
        datum?.periodEndDate
      ).format("DD, YYYY")}`;
    },
    sorter: (a: IAttendance, b: IAttendance) =>
      +new Date(a?.periodStartDate || "") - +new Date(b?.periodStartDate || ""),
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    key: "tags",
    render: (_: unknown, attendance: IAttendance) => {
      const _status = getDayStatus(attendance, {
        userType,
      });

      return _status ? (
        <Tag color={_status.color}>{_status.title}</Tag>
      ) : (
        <Tag>{startCase(attendance.status.split("_").join(" "))}</Tag>
      );
    },
    sorter: (a: IAttendance, b: IAttendance) => {
      const { title: aStatusTitle } = getDayStatus(a, {
        userType,
      });

      const { title: bStatusTitle } = getDayStatus(b, {
        userType,
      });

      return (aStatusTitle || "").localeCompare(bStatusTitle || "");
    },
  },
];
