import { useSearchParams } from "react-router-dom";

// Internal
import { ChildCertificate } from "@/api/__types/attendance";
import { useGetChildCertificates } from "@/api/attendance";
import { DATE_FORMAT_US } from "@/utils/dates";
import { useUrlParams } from "@/hooks/useUrlParams";
import StatusIndicator from "./StatusIndicator";
import { ACTIVE_CERTIFICATE_TOOLTIPS } from "@/pages/home/__components/active-certificates.columns";

// Antd
import { Alert, Card, Spin, Table, Tooltip } from "antd";

// Icons
import { InformationFilled } from "@carbon/icons-react";

// MISC
import dayjs from "dayjs";

export interface ChildActiveCertificatesProps {
  childId: string;
  platform: string;
  providerId: string;
}

export default function ChildActiveCertificates({
  childId,
  platform,
  providerId,
}: ChildActiveCertificatesProps) {
  // Search params
  const [searchParams] = useSearchParams();
  const endQueryParam = searchParams.get("end") || "";

  const { isProviderView } = useUrlParams({
    searchParams,
  });

  const { isLoading: childCertificatesLoading, data: childCertificates } =
    useGetChildCertificates({
      childId: childId || "",
      isProviderView,
      platform: platform || "",
      startDate:
        dayjs(endQueryParam).startOf("month").format("YYYY-MM-DD") || "",
      providerId: providerId || "",
    });

  const columns = [
    {
      title: "Certificate ID",
      dataIndex: "certificateId",
      key: "certificateId",
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
    },
    {
      title: "Approved Fee Assistance Amount",
      dataIndex: "feeAssistanceRate",
      key: "feeAssistanceRate",
      render: (text: string) => {
        return `$${parseFloat(text).toLocaleString()} / month`;
      },
    },
    {
      title: "Start Date",
      dataIndex: "dateCertBegins",
      key: "dateCertBegins",
      render: (text: string) => {
        return dayjs(text).format(DATE_FORMAT_US);
      },
    },
    {
      title: "End Date",
      dataIndex: "dateCertEnds",
      key: "dateCertEnds",
      render: (text: string, record: ChildCertificate) => {
        if (isProviderView && record.expiringSoon) {
          return (
            <div className="flex items-center gap-2">
              {dayjs(text).format(DATE_FORMAT_US)}{" "}
              <Tooltip
                title={
                  <>
                    <p>
                      Families nearing certificate expiration can reference the
                      “Notes Box” of their certificate approval to confirm any
                      supporting documentation required to maintain fee
                      assistance beyond the next 45 days.
                    </p>

                    <p className="mt-4">
                      If you are unable to determine what is needed or need
                      additional information regarding your account, please
                      contact us at 1-800-424-2246 for further assistance.
                    </p>
                  </>
                }
                placement="left"
                overlayStyle={{ maxWidth: "500px", width: "75vw" }}
              >
                <InformationFilled />
              </Tooltip>
            </div>
          );
        }

        return dayjs(text).format(DATE_FORMAT_US);
      },
    },
    ...(isProviderView
      ? []
      : [
          {
            dataIndex: "_",
            key: "_",
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render: (_: string, record: ChildCertificate) => {
              if (
                !record.expiringSoon &&
                !record.expiringSoonLow &&
                !record.expiringSoonCritical
              ) {
                return null;
              }

              const _statusType = record.expiringSoonCritical
                ? "critical"
                : record.expiringSoon
                  ? "major"
                  : "minor";

              return (
                <div className="ml-auto table">
                  <Tooltip
                    title={ACTIVE_CERTIFICATE_TOOLTIPS[_statusType]}
                    placement="left"
                  >
                    <div>
                      <StatusIndicator type={_statusType} size={18} />
                    </div>
                  </Tooltip>
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <Spin spinning={childCertificatesLoading}>
      <Card
        title="Active Certificates"
        className="mt-10 mb-10"
        styles={{
          body: {
            padding: "0px",
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={childCertificates}
          pagination={{
            hideOnSinglePage: true,
            style: { marginRight: "24px" },
          }}
          rowKey="id"
          rowClassName={(record: ChildCertificate) => {
            return record.expiringSoon ? "status-critical" : "";
          }}
          scroll={{ x: "max-content" }}
        />

        <div className="m-4">
          {isProviderView ? (
            <ProviderCertificatesLegend />
          ) : (
            <FamilyCertificatesLegend />
          )}
        </div>
      </Card>
    </Spin>
  );
}

function ProviderCertificatesLegend() {
  return (
    <Alert
      message={<p className="font-semibold">Legend</p>}
      description={
        <ul className="list-disc ml-6">
          <li>
            A certificate will only appear in RED, along with the{" "}
            <InformationFilled className="mt-[-2px] align-middle" /> icon, if it
            ends within 45 days of today's date with no additional Active
            approvals.
          </li>

          <li>
            Hover over the{" "}
            <InformationFilled className="mt-[-2px] align-middle" /> icon to
            determine what information the child needs to remain active.
          </li>

          <li>
            If there are any changes to the approval, such as schedule changes,
            rate changes, discounts, etc., those are to be reported to Child
            Care Aware® of America prior to submitting the attendance sheet for
            the month the change takes effect.
          </li>
        </ul>
      }
    />
  );
}

export function FamilyCertificatesLegend() {
  return (
    <Alert
      message={<p className="font-semibold">Legend</p>}
      description={
        <ul className="list-disc ml-6">
          <li>
            Hover over the icons to determine what information the child needs
            to remain active.
          </li>

          <li>
            Certificates highlighted in{" "}
            <span className="text-red-500 font-bold">RED</span> with the{" "}
            <StatusIndicator type="critical" size={18} /> icon indicate that the
            child will no longer have an ACTIVE certificate at a specific
            Provider within the next 30 days.
          </li>

          <li>
            The <StatusIndicator type="major" size={18} /> icon indicates that
            the child's current certificate will expire in the next 45 days.
          </li>

          <li>
            The <StatusIndicator type="minor" size={18} /> icon indicates that
            the child's current certificate will expire in the next 60 days.
          </li>

          <li>
            If there are no icons, then the certificate is active over 60 days
            from today’s date.
          </li>
        </ul>
      }
    />
  );
}
