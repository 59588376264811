// Internal
import { ChildCertificate } from "@/api/__types/attendance";
import StatusIndicator from "@/components/StatusIndicator";
import { DATE_FORMAT_US_SLASHES } from "@/utils/dates";
import { Tooltip } from "antd";

// MISC
import BigNumber from "bignumber.js";
import dayjs from "dayjs";

export const ACTIVE_CERTIFICATE_TOOLTIPS = {
  critical: (
    <>
      Families nearing certificate expiration can reference the “Notes Box” of
      their certificate approval to confirm any supporting documentation
      required to maintain fee assistance beyond the next{" "}
      <b className="font-bold">30</b> days. If you are unable to determine what
      is needed or need additional information regarding your account, please
      contact us at 1-800-424-2246 for further assistance.
    </>
  ),
  major: (
    <>
      Families nearing certificate expiration can reference the “Notes Box” of
      their certificate approval to confirm any supporting documentation
      required to maintain fee assistance beyond the next{" "}
      <b className="font-bold">45</b> days. If you are unable to determine what
      is needed or need additional information regarding your account, please
      contact us at 1-800-424-2246 for further assistance.
    </>
  ),
  minor: (
    <>
      Families nearing certificate expiration can reference the “Notes Box” of
      their certificate approval to confirm any supporting documentation
      required to maintain fee assistance beyond the next{" "}
      <b className="font-bold">60</b> days. If you are unable to determine what
      is needed or need additional information regarding your account, please
      contact us at 1-800-424-2246 for further assistance.
    </>
  ),
};

export const getActiveCertificatesColumns = () => [
  {
    title: "Provider Name",
    dataIndex: "providerName",
    key: "providerName",
    width: 250,
    sorter: (a: ChildCertificate, b: ChildCertificate) =>
      a.providerName.localeCompare(b.providerName),
  },
  {
    title: "Provider ID",
    dataIndex: "providerId",
    key: "providerId",
    width: 160,
  },
  {
    title: "Certificate ID",
    dataIndex: "certificateId",
    key: "certificateId",
    width: 160,
  },
  {
    title: "Service Type",
    dataIndex: "serviceType",
    key: "serviceType",
    width: 220,
    sorter: (a: ChildCertificate, b: ChildCertificate) =>
      a.serviceType.localeCompare(b.serviceType),
  },
  {
    title: "Approved Fee Assistance Amount",
    dataIndex: "feeAssistanceRate",
    key: "feeAssistanceRate",
    render: (text: string) => `$${BigNumber(text).toFormat()}/month`,
    width: 180,
    sorter: (a: ChildCertificate, b: ChildCertificate) =>
      +a.feeAssistanceRate - +b.feeAssistanceRate,
  },
  {
    title: "Start Date",
    dataIndex: "dateCertBegins",
    key: "dateCertBegins",
    render: (text: string) => dayjs(text).format(DATE_FORMAT_US_SLASHES),
    width: 160,
    sorter: (a: ChildCertificate, b: ChildCertificate) =>
      +new Date(a?.dateCertBegins || "") - +new Date(b?.dateCertBegins || ""),
    defaultSortOrder: "ascend",
  },
  {
    title: "End Date",
    dataIndex: "dateCertEnds",
    key: "dateCertEnds",
    render: (text: string) => dayjs(text).format(DATE_FORMAT_US_SLASHES),
    width: 160,
    sorter: (a: ChildCertificate, b: ChildCertificate) =>
      +new Date(a?.dateCertEnds || "") - +new Date(b?.dateCertEnds || ""),
  },
  {
    dataIndex: "_",
    key: "_",
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render: (_: string, record: ChildCertificate) => {
      if (
        !record.expiringSoon &&
        !record.expiringSoonLow &&
        !record.expiringSoonCritical
      ) {
        return null;
      }

      const _statusType = record.expiringSoonCritical
        ? "critical"
        : record.expiringSoon
          ? "major"
          : "minor";

      return (
        <div className="ml-auto table">
          <Tooltip
            title={ACTIVE_CERTIFICATE_TOOLTIPS[_statusType]}
            placement="left"
          >
            <div>
              <StatusIndicator type={_statusType} size={18} />
            </div>
          </Tooltip>
        </div>
      );
    },
  },
];
