import {
  WarningAltFilled,
  WarningAltInvertedFilled,
  WarningFilled,
} from "@carbon/icons-react";

export default function StatusIndicator({
  type,
  size,
}: {
  type: "critical" | "major" | "minor";
  size?: number;
}) {
  if (type === "critical") {
    return <WarningFilled className="align-middle text-red-500" size={size} />;
  }

  if (type === "major") {
    return (
      <div className="relative inline-table">
        <div className="absolute top-[27%] left-1/2 -translate-x-1/2 w-[2px] h-[40%] flex items-center justify-center bg-[#000] z-0" />

        <WarningAltInvertedFilled
          className="relative align-middle text-orange-400 z-10"
          size={size}
        />
      </div>
    );
  }

  // minor
  return (
    <div className="relative inline-table">
      <div className="absolute top-[45%] left-1/2 -translate-x-1/2 w-[2px] h-[40%] flex items-center justify-center bg-[#000] z-0" />

      <WarningAltFilled
        className="relative align-middle text-yellow-300 z-10"
        size={size}
      />
    </div>
  );
}
