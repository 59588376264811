import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

// Internal
import { useUrlParams } from "@/hooks/useUrlParams";
import { DayStatus } from "@/types/attendance-sheet.types";
import EasScheduleForm from "./__components/EasScheduleForm";
import EasAcknowledgements from "./__components/EasAcknowledgements";
import Container from "@/components/Container";
import {
  useGetAttendanceDetails,
  useSubmitAttendanceForFamily,
  useSubmitAttendanceForProvider,
} from "@/api/attendance";
import {
  SubmitAttendanceForFamilyDto,
  SubmitAttendanceForProviderDto,
} from "@/api/__types/attendance";
import ReadOnlyView from "./__components/EasScheduleReadyOnlyView";
import { API_ENTRYPOINT } from "@/utils/fetch";
import {
  getAllDatesInBetween,
  isValidDateString,
  parseDayMonthYearString,
} from "@/utils/dates";
import GeneralErrorState from "@/components/GeneralErrorState";
import ChildActiveCertificates from "@/components/ChildActiveCertificates";

// Antd
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Layout,
  notification,
  Radio,
  Space,
  Spin,
} from "antd";
import {
  CaretLeftOutlined,
  CheckOutlined,
  HomeOutlined,
} from "@ant-design/icons";

// MISC
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { cloneDeep } from "lodash-es";

const { Footer } = Layout;

export default function Attendance() {
  const { attendanceId: selectedAttendanceSheetId } = useParams();
  const navigate = useNavigate();

  // Search params
  const [searchParams] = useSearchParams();

  // Fetch/pull from cache... the attendance list

  const platform = searchParams.get("platform");

  const { providerId, familyId, isProviderView, isFamilyView } = useUrlParams({
    searchParams,
  });

  const {
    isLoading: attendanceSheetLoading,
    data: attendanceSheet,
    isError: attendanceSheetDetailsErrored,
    error: attendanceSheetDetailsError,
  } = useGetAttendanceDetails({
    attendanceId: selectedAttendanceSheetId || "",
    platform: platform || "",
  });

  const child = attendanceSheet?.child;

  const startDate = attendanceSheet?.periodStartDate;
  const endDate = attendanceSheet?.periodEndDate;

  // Antd form
  const [form] = Form.useForm();
  const [wasTerminated, setWasTerminated] = useState(false);

  // File upload
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  // Mutations
  const { mutate: submitAttendanceForProvider } =
    useSubmitAttendanceForProvider();
  const { mutate: submitAttendanceForFamily } = useSubmitAttendanceForFamily();

  const [submitting, setSubmitting] = React.useState(false);
  const [submissionErrored, setSubmissionErrored] = React.useState(false);
  const onSubmit = (values: any) => {
    /*
     * Submission for a provider
     */
    if (isProviderView) {
      const _performSubmission = () => {
        const _days =
          attendanceSheet?.days?.map((day) => {
            if (
              // if day is weekend
              dayjs(day.dateAttended).day() === 0 ||
              dayjs(day.dateAttended).day() === 6
            ) {
              day.code = DayStatus.NO_CARE_PROVIDED;
            } else if (
              // if day is same as termination date
              terminationDate &&
              dayjs(day.dateAttended).date() === terminationDate.date()
            ) {
              day.code = DayStatus.LAST_DAY;
            } else if (
              // if day is after termination date
              terminationDate &&
              dayjs(day.dateAttended).isAfter(terminationDate)
            ) {
              day.code = DayStatus.NO_CARE_PROVIDED;
            } else if (
              daysNotInCare?.[dayjs(day.dateAttended).date()] === true
            ) {
              day.code = DayStatus.NO_CARE_PROVIDED;
            } else {
              day.code = DayStatus.ATTENDED;
            }

            return day;
          }) || [];
        const _hasNoCareProvidedDays = _days.some(
          (day) => day.code === DayStatus.NO_CARE_PROVIDED
        );

        // Prepare payload
        const _payload: SubmitAttendanceForProviderDto = {
          childId: child?.id || -1,
          attendanceId: attendanceSheet?.id || -1,
          providerId: providerId || -1,
          platform: attendanceSheet?.platform || "",
          submittedBy: values.providerName,
          // Whether or not care was received for the entire month
          attendedEntireMonth: !_hasNoCareProvidedDays,
          chargedForEntireMonth:
            values.chargedForEntireMonth === false ? false : true,
          // If care was not received for the entire month, report all the days
          days: _days,
          // Termination date
          terminated: !!terminationDate,
          terminationDate: terminationDate
            ? terminationDate?.format("YYYY-MM-DD") || ""
            : "",
          // Billing statement
          amountBilled: values.amountBilled,
        };

        // Submit data to the server
        submitAttendanceForProvider(_payload, {
          onSuccess: () => {
            notification.success({
              message: "Attendance sheet submitted",
              description:
                "Once the family sponsor has submitted their agreement, a payment coordinator will review and issue payment accordingly.",
            });

            navigate(`/?${searchParams.toString()}`);
          },
          onError: (error) => {
            console.log("Error submitting", error);

            setSubmissionErrored(true);
          },
          onSettled: () => {
            setSubmitting(false);
          },
        });
      };

      setSubmitting(true);

      // Submit immediately if no file to upload
      if (!fileToUpload) {
        _performSubmission();
      } else {
        const formData = new FormData();
        formData.append("billingStatement", fileToUpload);
        formData.append("platform", attendanceSheet?.platform || "");

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${API_ENTRYPOINT}/attendance/${attendanceSheet?.id}/file`,
          data: formData,
        };

        axios
          .request(config)
          .then(() => {
            _performSubmission();
          })
          .catch((error) => {
            console.log("Error uploading file", error);

            setSubmissionErrored(true);
            setSubmitting(false);
          });
      }
    } else {
      /*
       * Submission for a parent
       */
      setSubmitting(true);

      // Prepare payload
      const _payload: SubmitAttendanceForFamilyDto = {
        familyId: familyId || -1,
        familyAccepted: values.parentInAgreementWithProviderDetails,
        declineReason: values.declineReason,
        attendanceId: attendanceSheet?.id || -1,
        childId: child?.id || -1,
        platform: attendanceSheet?.platform || "",
        submittedBy: values.parentName,
      };

      // Submit data to the server
      submitAttendanceForFamily(_payload, {
        onSuccess: () => {
          notification.success({
            message: "Submitted",
            description:
              "We will report this information back to your provider.",
          });

          navigate(`/?${searchParams.toString()}`);
        },
        onError: (error) => {
          console.log("Error submitting", error);

          setSubmissionErrored(true);
        },
        onSettled: () => {
          setSubmitting(false);
        },
      });
    }
  };

  const [receivedCareForEntireMonth, setReceivedCareForEntireMonth] =
    React.useState(true);
  const [daysNotInCare, setDaysNotInCare] = React.useState<{
    [key: number]: boolean;
  }>({});
  const [terminationDate, setTerminationDate] =
    React.useState<dayjs.Dayjs | null>(null);

  /*
   * Redirect to home if attendance sheet is not found
   * or if the attendance sheet is not editable
   */
  useEffect(() => {
    const _status = attendanceSheet?.status?.toLowerCase();
    const _isEditable = !(_status === "incomplete" && isFamilyView);
    if (!_isEditable) {
      navigate(`/?${searchParams.toString()}`);

      return;
    }
  }, [
    attendanceSheetLoading,
    attendanceSheet,
    isFamilyView,
    navigate,
    searchParams,
  ]);

  const _providerHasSubmitted =
    attendanceSheet?.submittedByProvider ||
    attendanceSheet?.submittedByProviderDate ||
    attendanceSheet?.status === "FAMILY_APPROVED";
  const _parentHasSubmitted =
    attendanceSheet?.status === "FAMILY_APPROVED" ||
    attendanceSheet?.submittedByFamily ||
    attendanceSheet?.submittedByFamilyDate;

  const _showProviderResubmissionUI =
    isProviderView && (attendanceSheet?.status || "") === "FAMILY_DENIED";

  const isReadOnly =
    attendanceSheet?.status === "APRVS" ||
    (isProviderView && _providerHasSubmitted && !_showProviderResubmissionUI) ||
    (isFamilyView &&
      _parentHasSubmitted &&
      attendanceSheet.status !== "PROVIDER_SUBMITTED");

  /*
   * If in re-submission mode, then we need to
   * show the originally filled out attendance sheet
   */
  useEffect(() => {
    if (attendanceSheet && _showProviderResubmissionUI) {
      /*
       * Determine days not in care
       */
      const _daysNotInCare = {};
      attendanceSheet?.days?.forEach((day) => {
        if (day.code !== DayStatus.ATTENDED) {
          _daysNotInCare[dayjs(day.dateAttended).date()] = true;
        }
      });
      setDaysNotInCare(_daysNotInCare);

      /*
       * Determine if care was received for the entire month
       */
      const _hasNoCareProvidedDays = attendanceSheet?.days?.some(
        (day) => day.code === DayStatus.NO_CARE_PROVIDED
      );
      setReceivedCareForEntireMonth(!_hasNoCareProvidedDays);

      // Termination date
      if (isValidDateString(attendanceSheet?.terminationDate)) {
        setTerminationDate(
          parseDayMonthYearString(attendanceSheet?.terminationDate)
        );

        form.setFieldsValue({
          terminationDate: parseDayMonthYearString(
            attendanceSheet?.terminationDate
          ),
        });

        setWasTerminated(true);
      }
    }
  }, [attendanceSheet, form, _showProviderResubmissionUI]);

  return (
    <Container>
      {attendanceSheetDetailsErrored || submissionErrored ? (
        <GeneralErrorState error={attendanceSheetDetailsError} />
      ) : !child ? (
        <Spin spinning />
      ) : (
        <>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={`/?${searchParams.toString()}`}>
                    <HomeOutlined />
                    <span className="ml-2">Back to Home</span>
                  </Link>
                ),
              },
            ]}
            className="ml-1 mb-4"
          />

          <Form
            layout="vertical"
            form={form}
            onFinish={(values) => {
              onSubmit(values);
            }}
          >
            <Card
              title={`${child.name} (ID: ${child.id})`}
              styles={{
                body: {
                  padding: "0px",
                },
              }}
            />

            <ChildActiveCertificates
              childId={attendanceSheet?.child?.id}
              platform={platform || ""}
              providerId={
                isProviderView ? providerId : attendanceSheet?.provider?.id
              }
            />

            {isReadOnly || isFamilyView ? (
              <Card
                title="Review provider’s submitted schedule"
                styles={{ body: { padding: "0px", overflow: "hidden" } }}
              >
                <div className="relative" style={{ padding: "24px" }}>
                  <ReadOnlyView attendanceSheet={attendanceSheet} />
                </div>
              </Card>
            ) : (
              <>
                <Card
                  title="Attendance Sheet"
                  styles={{ body: { padding: "0px", overflow: "hidden" } }}
                >
                  <div className="relative" style={{ padding: "24px" }}>
                    {_showProviderResubmissionUI && (
                      <Alert
                        className="mb-8"
                        message={
                          <span className="font-bold">Needs re-submission</span>
                        }
                        description={
                          <>
                            <span>
                              This attendance sheet was rejected by the family.
                              Please edit below, and re-submit.
                            </span>

                            {attendanceSheet.declineReason && (
                              <>
                                <Divider />

                                <p className="font-bold">
                                  Reason family declined:
                                </p>
                                <p>{attendanceSheet.declineReason}</p>
                              </>
                            )}
                          </>
                        }
                        type="warning"
                        showIcon
                        closable
                      />
                    )}

                    {(isProviderView || terminationDate) && (
                      <div className="w-full xl:w-1/3 py-12 xl:py-0">
                        {isProviderView ? (
                          <Form.Item
                            label={
                              <span className="text-black font-bold">
                                Was care ended in{" "}
                                {parseDayMonthYearString(endDate).format(
                                  "MMMM"
                                )}
                                ?
                              </span>
                            }
                          >
                            <Radio.Group
                              value={wasTerminated}
                              onChange={(e) => {
                                setWasTerminated(e.target.value);

                                if (e.target.value !== true) {
                                  setTerminationDate(null);
                                  form.setFieldsValue({
                                    terminationDate: null,
                                  });
                                }
                              }}
                            >
                              <Space direction="horizontal">
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        ) : (
                          <p className="text-lg font-semibold tracking-normal mb-6">
                            Termination date
                          </p>
                        )}

                        {wasTerminated && (
                          <Form.Item
                            name="terminationDate"
                            label={
                              isProviderView ? (
                                <span className="text-black font-bold">
                                  Termination date
                                </span>
                              ) : undefined
                            }
                            rules={[
                              {
                                required: true,
                                message: "Please select a date",
                              },
                            ]}
                          >
                            <DatePicker
                              size="large"
                              onChange={(value) => {
                                setTerminationDate(value);

                                // Update the daysNotInCare state
                                // to include the dates in between the start and end date
                                const _newDaysNotInCare =
                                  cloneDeep(daysNotInCare);

                                getAllDatesInBetween({
                                  startDate: value as Dayjs,
                                  endDate: parseDayMonthYearString(endDate),
                                  includeStart: false,
                                  includeEnd: true,
                                }).forEach((date) => {
                                  _newDaysNotInCare[date.date()] = true;
                                });

                                setDaysNotInCare(_newDaysNotInCare);
                              }}
                              minDate={parseDayMonthYearString(startDate)}
                              maxDate={parseDayMonthYearString(endDate)}
                              defaultPickerValue={parseDayMonthYearString(
                                endDate
                              )}
                              // disable weekends
                              disabledDate={(day) =>
                                day.day() === 0 || day.day() === 6
                              }
                              style={{ minWidth: "270px" }}
                            />
                          </Form.Item>
                        )}
                      </div>
                    )}

                    {isProviderView && (
                      <Form.Item
                        label={
                          <span className="text-black font-bold">
                            Did child receive care for the entire month?
                          </span>
                        }
                      >
                        <Radio.Group
                          value={receivedCareForEntireMonth}
                          onChange={(e) => {
                            setReceivedCareForEntireMonth(e.target.value);
                          }}
                        >
                          <Space direction="horizontal">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </div>
                </Card>

                {(!receivedCareForEntireMonth || isFamilyView) && (
                  <EasScheduleForm
                    attendaceSheet={attendanceSheet}
                    child={child}
                    daysNotInCare={daysNotInCare}
                    setDaysNotInCare={setDaysNotInCare}
                    terminationDate={terminationDate}
                    setTerminationDate={setTerminationDate}
                    fileToUpload={fileToUpload}
                    setFileToUpload={setFileToUpload}
                    showProviderResubmissionUI={_showProviderResubmissionUI}
                  />
                )}
              </>
            )}

            {isReadOnly ? (
              <AttendanceFooter
                isReadOnly={isReadOnly}
                searchParams={searchParams}
                submitting={submitting}
              />
            ) : (
              <Card
                title={
                  !isReadOnly
                    ? isProviderView
                      ? "FINAL ACKNOWLEDGEMENT"
                      : "Your Response"
                    : undefined
                }
                styles={{ body: { padding: "0px", overflow: "hidden" } }}
                className="mt-10"
              >
                {!isReadOnly && (
                  <div className="relative" style={{ padding: "24px" }}>
                    <EasAcknowledgements
                      attendanceSheet={attendanceSheet}
                      showProviderResubmissionUI={_showProviderResubmissionUI}
                    />
                  </div>
                )}

                <AttendanceFooter
                  isReadOnly={isReadOnly}
                  searchParams={searchParams}
                  submitting={submitting}
                />
              </Card>
            )}
          </Form>
        </>
      )}
    </Container>
  );
}

function AttendanceFooter({
  isReadOnly,
  searchParams,
  submitting,
}: {
  isReadOnly: string | boolean | null | undefined;
  searchParams: URLSearchParams;
  submitting: boolean;
}) {
  const form = Form.useFormInstance();

  return (
    <Footer
      className={`bg-black bg-opacity-10 ${
        isReadOnly ? "rounded-lg mt-6" : "rounded-b-lg"
      } flex items-center`}
      style={{ textAlign: "center", padding: "24px" }}
    >
      <Link to={`/?${searchParams.toString()}`} className="text-current">
        <CaretLeftOutlined className="mr-2" />
        Back
      </Link>

      <div className="flex-grow" />

      {!isReadOnly && (
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
          loading={submitting}
        >
          Submit <CheckOutlined />
        </Button>
      )}
    </Footer>
  );
}
