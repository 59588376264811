import { useState } from "react";

// Internal
import Container from "./Container.tsx";
import {
  MIN_DATE_THRESHOLD,
  useGenerateAttendanceSheetLink,
} from "@/api/attendance.ts";

// Antd
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  notification,
  Select,
} from "antd";

// MISC
import { get } from "lodash-es";
import dayjs from "dayjs";

const { Header } = Layout;

const isDevOrStaging = ["dev", "staging"].includes(
  import.meta.env.VITE_SITE_ENV
);

export default function LinkGenerator() {
  const [form] = Form.useForm();

  const { mutate: generateLink } = useGenerateAttendanceSheetLink();
  const [linkBeingGenerated, setLinkBeingGenerated] = useState(false);

  return (
    <div>
      <Layout className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <Header
            className={`px-4 sm:px-12${isDevOrStaging ? " bg-[#fbad3b] border-b border-solid border-b-[#00142a4a]" : ""}`}
            style={{ height: "100px", display: "flex", alignItems: "center" }}
          >
            <div>
              <img
                src="/images/ccaoa-logo.png"
                alt="Logo"
                className="table h-20 mr-6"
              />
            </div>

            <div className="flex-grow text-white text-right">
              {isDevOrStaging && (
                <div className="h-4 leading-none text-[#000] opacity-70">
                  {import.meta.env.VITE_SITE_ENV === "dev" ? "DEV" : "STAGING"}{" "}
                  environment
                </div>
              )}
            </div>
          </Header>

          <div className="pb-[200px]">
            <Container className="!max-w-[900px]">
              <Form
                form={form}
                layout="vertical"
                onFinish={(values) => {
                  setLinkBeingGenerated(true);

                  generateLink(values, {
                    onSuccess: () => {
                      notification.success({
                        message: "Email sent",
                        description:
                          "Your Electronic Attendance Sheet link has been sent to the associated emails.",
                      });
                    },
                    onError: (error) => {
                      console.log("Error generating link", error);

                      notification.error({
                        message: "Error sending email",
                        description: `${
                          get(error, "response.data.detail") ||
                          "There was an issue sending the email"
                        }. Please try again or contact support.`,
                      });
                    },
                    onSettled: () => {
                      setLinkBeingGenerated(false);
                    },
                  });
                }}
              >
                <Card
                  title="Electronic Attendance Sheet Link Generator"
                  styles={{
                    body: {
                      padding: "0px",
                    },
                  }}
                >
                  <div className="p-4">
                    <Alert
                      showIcon
                      message={
                        <span className="font-semibold">Instructions:</span>
                      }
                      description="In order to generate a link to your Electronic
                          Attendance Sheet webpage, select the Generate button.
                          All email addresses listed on your Fee Assistance
                          account will receive a link in their inbox."
                    />

                    <div className="mt-8 block gap-8 md:flex m-auto justify-center">
                      <Form.Item
                        name="userType"
                        label="User type"
                        className="w-full md:w-1/3"
                        rules={[
                          {
                            required: true,
                            message: "Please select a user type",
                          },
                        ]}
                      >
                        <Select
                          options={[
                            {
                              label: "Family",
                              value: "FAMILY",
                            },
                            {
                              label: "Provider",
                              value: "PROVIDER",
                            },
                          ]}
                        />
                      </Form.Item>

                      <Form.Item
                        name="userId"
                        label="Enter your ID"
                        className="w-full md:w-1/3"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your ID",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="billingMonthYear"
                        label="Billing month & year"
                        rules={[
                          {
                            required: true,
                            message: "Please select a billing month & year",
                          },
                        ]}
                        className="w-full md:w-1/3"
                      >
                        <DatePicker
                          picker="month"
                          className="w-full"
                          format="MMM YYYY"
                          allowClear={false}
                          disabledDate={(current) => {
                            // Disable dates before the minimum threshold
                            if (current.isBefore(MIN_DATE_THRESHOLD)) {
                              return true;
                            }

                            if (current.isSame(dayjs(), "month")) {
                              // Disable the current month if it's before the 25th
                              return dayjs().date() < 25;
                            }

                            return current > dayjs().endOf("month");
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <Divider className="my-0" />

                  <div className="p-4">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={linkBeingGenerated}
                      className="m-auto px-8"
                    >
                      Generate
                    </Button>
                  </div>
                </Card>

                <Card title="Helpful hints" className="mt-8">
                  <ul>
                    <li>
                      To find your ID, review the number listed on the approved
                      certificate you received for the child.
                    </li>

                    <li>
                      If you don’t receive an email, you may need to update your
                      preferences by contacting CCAoA and submitting a Change of
                      Information Form.
                    </li>
                  </ul>
                </Card>
              </Form>
            </Container>
          </div>
        </div>
      </Layout>
    </div>
  );
}
