import dayjs, { Dayjs } from "dayjs";

export const DATE_FORMAT_US = "MM-DD-YYYY";
export const DATE_FORMAT_US_SLASHES = "MM/DD/YYYY";

export function parseEndDateQueryParam(endDate: string): Dayjs {
  const _split = endDate.split("-").map((x) => parseInt(x, 10));
  return dayjs(new Date(_split[0], _split[1] - 1));
}

/**
 * Returns the current date in string format.
 * @returns The current date in string format.
 */
export function parseDayMonthYearString(dateString: string): dayjs.Dayjs {
  return dayjs(dateString).local();
}

/**
 * Returns the name of the month that the date range mostly falls into.
 * @param startDate - The start date in string format.
 * @param endDate - The end date in string format.
 * @returns The name of the month that the date range mostly falls into.
 */
export function getDateRangeString(startDate: string, endDate: string): string {
  const start = parseDayMonthYearString(startDate);
  const end = parseDayMonthYearString(endDate);

  const midDate = start.add(end.diff(start) / 2, "millisecond");

  return midDate.format("MMMM YYYY");
}

export function isValidDateString(dateString: string) {
  if (!dateString) return false;

  const date = new Date(dateString);

  return !isNaN(date.getTime());
}

/**
 * Returns all the dates in between the start and end date.
 * @param options.startDate - The start date.
 * @param options.endDate - The end date.
 * @param options.includeStart - Whether to include the start date.
 * @param options.includeEnd - Whether to include the end date.
 * @returns All the dates in between the start and end date.
 */
export function getAllDatesInBetween(options: {
  startDate: Dayjs;
  endDate: Dayjs;
  includeStart?: boolean;
  includeEnd?: boolean;
}): Dayjs[] {
  const {
    startDate,
    endDate,
    includeStart = true,
    includeEnd = true,
  } = options;
  const dates: Dayjs[] = [];
  let currentDate = startDate.startOf("day");

  while (currentDate.isBefore(endDate.startOf("day"))) {
    if (includeStart || currentDate.isAfter(startDate)) {
      dates.push(currentDate);
    }

    currentDate = currentDate.add(1, "day");
  }

  if (includeEnd) {
    dates.push(endDate);
  }

  return dates;
}
