export enum AttendanceStatus {
  INCOMPLETE = "INCOMPLETE",
  PROVIDER_SUBMITTED = "PROVIDER_SUBMITTED",
  FAMILY_APPROVED = "FAMILY_APPROVED",
  FAMILY_DENIED = "FAMILY_DENIED",
  APRVS = "APRVS",
}

export interface IAttendance {
  id: number;
  platform: string;
  status: AttendanceStatus;
  child: {
    id: number;
    householdId: number;
    name: string;
  };
  periodStartDate: string;
  periodEndDate: string;
  provider: {
    id: number;
    name: string;
    address: {
      street: string;
      city: string;
      state: string;
      postalCode: string;
    };
    phoneNumber: string;
  };
  days: {
    id: number;
    code: (typeof DayStatus)[keyof typeof DayStatus];
    dateAttended: string;
  }[];
  submittedByProvider: null | string;
  submittedByProviderDate: null | string;
  acceptedByFamily: null | string;
  submittedByFamily: null | string;
  submittedByFamilyDate: null | string;
  termination: null | boolean;
  terminationDate: any;
  chargedForMonth: null | boolean;
  amountBilled: null | number;
}

export const DayStatus = {
  ATTENDED: "ATTENDED",
  NO_CARE_PROVIDED: "NO_CARE_PROVIDED",
  LAST_DAY: "LAST_DAY",
} as const;

export const DayStatusColor = {
  [DayStatus.ATTENDED]: "bg-slate-800",
  [DayStatus.NO_CARE_PROVIDED]: "bg-blue-800",
} as const;

export const AttendanceStatusTitles = {
  provider: {
    [AttendanceStatus.INCOMPLETE]: "Incomplete",
    [AttendanceStatus.PROVIDER_SUBMITTED]: "Awaiting Family",
    [AttendanceStatus.FAMILY_APPROVED]: "Submitted for payment",
    [AttendanceStatus.FAMILY_DENIED]: "Family rejected",
    [AttendanceStatus.APRVS]: "Payment Sent",
  },
  family: {
    [AttendanceStatus.INCOMPLETE]: "Awaiting provider",
    [AttendanceStatus.PROVIDER_SUBMITTED]: "Awaiting your response",
    [AttendanceStatus.FAMILY_APPROVED]: "Submitted for payment",
    [AttendanceStatus.FAMILY_DENIED]: "Contact Provider",
    [AttendanceStatus.APRVS]: "Payment Sent",
  },
};

export const AttendanceStatusColors = {
  provider: {
    [AttendanceStatus.INCOMPLETE]: "warning",
    [AttendanceStatus.PROVIDER_SUBMITTED]: "processing",
    [AttendanceStatus.FAMILY_APPROVED]: "green",
    [AttendanceStatus.FAMILY_DENIED]: "error",
    [AttendanceStatus.APRVS]: "green",
  },
  family: {
    [AttendanceStatus.INCOMPLETE]: "",
    [AttendanceStatus.PROVIDER_SUBMITTED]: "warning",
    [AttendanceStatus.FAMILY_APPROVED]: "green",
    [AttendanceStatus.FAMILY_DENIED]: "error",
    [AttendanceStatus.APRVS]: "green",
  },
};
